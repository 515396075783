import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";

export default function MonthlyPendingComplaintGraphQV({ data }) {
  const calculatePercentage = (value, total) => {
    return total > 0 ? ((value / total) * 100).toFixed(1) : 0;
  };

  return (
    <ResponsiveContainer width="100%" height={370}>
      <BarChart data={data} margin={{ top: 5, bottom: 5 }}>
        <XAxis
          type="category"
          dataKey="vendorName"
          interval={0}
          axisLine={false}
          tick={{
            fontSize: 8,
            fontWeight: "bold",
            fill: "#000",
            width: 60,
            whiteSpace: "pre-wrap",
            overflow: "hidden",
          }}
          tickFormatter={(vendorName) => vendorName}
        />
        <YAxis
          hide
          axisLine={true}
          type="number"
          tickLine={false}
          domain={[0, (max) => max + max * 0.1]}
        />
        <Tooltip />
        <Legend />

        <Bar
          dataKey="inTime"
          stackId="a"
          fill="#3862DD"
          name="Within SLA"
          barSize={70}
        >
          <LabelList
            dataKey="inTime"
            position="middle"
            fill="#fff"
            content={({ x, y, width, height, value, index }) => {
              const percentage = calculatePercentage(
                value,
                data[index]?.totalComplaints
              );
              return (
                <text
                  x={x + width / 2}
                  y={y + height / 2}
                  textAnchor="middle"
                  fill="#fff"
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    fontSize: 10,
                    fontWeight: "bolder",
                  }}
                >
                  {value} ({percentage}%)
                </text>
              );
            }}
          />
        </Bar>

        <Bar
          dataKey="outOfTime"
          stackId="a"
          fill="#F3841D"
          name="Beyond SLA"
          radius={[10, 10, 0, 0]}
          barSize={70}
        >
          <LabelList
            dataKey="outOfTime"
            position="middle"
            fill="#fff"
            content={({ x, y, width, height, value, index }) => {
              const percentage = calculatePercentage(
                value,
                data[index]?.totalComplaints
              );
              return (
                <text
                  x={x + width / 2}
                  y={y + height / 2}
                  textAnchor="middle"
                  fill="#fff"
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    fontSize: 10,
                    fontWeight: "bolder",
                  }}
                >
                  {value} ({percentage}%)
                </text>
              );
            }}
          />
          <LabelList
            dataKey="totalComplaints"
            position="top"
            fill="#979797"
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: 12,
              fontWeight: "bolder",
            }}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
