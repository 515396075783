import React from "react";
import useStyles from "./style";
import moment from "moment";
import { BASE_URL } from "../../../utils/base";
import { useNavigate } from "react-router-dom";
import { handlePropertyName } from "../../../contextAPI/NamingConvention";
import { navigateToDiscussionDetails } from "../../../Navigation/Navigations";

function DiscussionCards(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { discussion, page } = props;
  const localPageNumber = parseInt(localStorage.getItem("discussionPageNumber"))
    ? parseInt(localStorage.getItem("discussionPageNumber"))
    : page;
  return (
    <div className="col-lg-4 col-md-6">
      <div
        className="card"
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          borderRadius: "25px",
        }}
      >
        <div className="card-body">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center gap-2">
                <img
                  style={{
                    cursor: "pointer",
                    border: "1px solid #e6e7f0",
                    height: 36,
                    width: 36,
                    borderRadius: 50,
                  }}
                  src={
                    discussion?.addedBy?.image
                      ? `${BASE_URL}/user/${discussion?.addedBy?.image}`
                      : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                  }
                  alt="Sociohood"
                />
                <div>
                  <p className="mb-0 text-poppins text-capitalize text-navy w-100">
                    {discussion?.addedBy?.name?.length > 18
                      ? discussion?.addedBy?.name.slice(0, 18).concat("...")
                      : discussion?.addedBy?.name}
                  </p>
                  <p className="mb-0 text-poppins" style={{ fontSize: "12px" }}>
                    {moment(discussion?.createdAt).format(
                      "DD/MM/YYYY - h:mm A"
                    )}
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center gap-1">
                <>
                  {discussion?.viewerType === "societyWise" ? (
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.25 15.75H15.75M3.75 15.75V5.25L9.75 2.25V15.75M14.25 15.75V8.25L9.75 5.25M6.75 6.75V6.7575M6.75 9V9.0075M6.75 11.25V11.2575M6.75 13.5V13.5075"
                        stroke="#5E5E5E"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.75 8H1.25L8 1.25L14.75 8H13.25"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.75 8V13.25C2.75 13.6478 2.90804 14.0294 3.18934 14.3107C3.47064 14.592 3.85218 14.75 4.25 14.75H11.75C12.1478 14.75 12.5294 14.592 12.8107 14.3107C13.092 14.0294 13.25 13.6478 13.25 13.25V8"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.5 8H9.5V11H6.5V8Z"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                </>
                {discussion?.viewerType === "societyWise" ? (
                  <>Society Wise</>
                ) : (
                  <>
                    {discussion?.properties?.length}&nbsp;
                    {handlePropertyName()}
                  </>
                )}
              </div>
            </div>
            <div>
              <p className="mb-0 text-poppins text-navy fw-bold w-100 text-truncate">
                {discussion?.discussionTitle?.length > 20
                  ? discussion?.discussionTitle.slice(0, 20).concat("...")
                  : discussion?.discussionTitle}
              </p>
              <div className="mx-auto" style={{ height: "140px", width: "200px" }}>
                <img
                  src={
                    discussion?.images?.length > 0
                      ? discussion?.images[0]
                      : "https://www.ncenet.com/wp-content/uploads/2020/04/No-image-found.jpg"
                  }
                  alt="discussion"
                  className="w-100 h-100"
                  style={{ borderRadius: "20px", objectFit: "contain" }}
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between gap-3">
              <div className="d-flex align-items-center gap-3">
                <p className="mb-0 d-flex align-itemms-center gap-1 text-poppins text-nowrap">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 9H16M8 13H14M18 4C18.7956 4 19.5587 4.31607 20.1213 4.87868C20.6839 5.44129 21 6.20435 21 7V15C21 15.7956 20.6839 16.5587 20.1213 17.1213C19.5587 17.6839 18.7956 18 18 18H13L8 21V18H6C5.20435 18 4.44129 17.6839 3.87868 17.1213C3.31607 16.5587 3 15.7956 3 15V7C3 6.20435 3.31607 5.44129 3.87868 4.87868C4.44129 4.31607 5.20435 4 6 4H18Z"
                      stroke="#03045E"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {discussion?.commentSection?.length
                    ? discussion?.commentSection?.length + " comments"
                    : "No comments"}
                </p>
                <p className="mb-0 d-flex align-itemms-center gap-1 text-poppins text-nowrap">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 12C10 12.5304 10.2107 13.0391 10.5858 13.4142C10.9609 13.7893 11.4696 14 12 14C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12Z"
                      stroke="#03045E"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M21 12C18.6 16 15.6 18 12 18C8.4 18 5.4 16 3 12C5.4 8 8.4 6 12 6C15.6 6 18.6 8 21 12Z"
                      stroke="#03045E"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  seen by&nbsp;{discussion?.viewedBy?.length}
                </p>
              </div>
              <div
                className="btn p-0 rounded-circle"
                onClick={() => {
                  navigate(
                    navigateToDiscussionDetails({
                      disucssionId: discussion?._id,
                    })
                  );
                }}
              >
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 62 62"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="62" height="62" rx="31" fill="#3862DD" />
                  <path
                    d="M15.042 31H45.9587M45.9587 31L32.7087 43.5M45.9587 31L32.7087 18.5"
                    stroke="white"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiscussionCards;
