import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import ApiCall from "../../api/ApiCall";
import { AreaRoutes } from "../../api/Routes";
import { startApiCall } from "../../GlobalFunctions/GlobalFunctions";
import { property } from "lodash";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    fontWeight: "bold",
    fontFamily: "Montserrat, sans-serif",
  },
  clearIndicator: {
    right: "-4px",
    marginTop: -8,
  },
}));

function ChooseAreaDropDown({
  selectedArea,
  setselectedArea,
  isPunePlant,
  property,
  flat
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [allArea, setallArea] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [page, setpage] = useState(1);
  const [searchKey, setsearchKey] = useState("");

  const handleScroll = (e) => {
    const target = e.target;
    if (
      target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight &&
      !loading &&
      totalCount !== allArea?.length
    ) {
      setpage((prev) => prev + 1);
    }
  };

  const handleGetAllArea = async () => {
    startApiCall(null, setLoading);
    const data = {
      filters: {
        societyId: fetchSocietyId(),
        isDeleted: false,
        searchKey,
      },
      page: page,
      limit: 500,
    };
    const res = await ApiCall("post", AreaRoutes.getArea, data);
    if (res?.success) {
      if (page === 1) {
        setallArea(res?.area?.data);
        settotalCount(res?.area?.totalCounts);
      } else {
        setallArea((prev) => [...prev, ...res?.area?.data]);
        settotalCount(res?.area?.totalCounts);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isPunePlant && flat?.name==="Toilet" && property?.name&& allArea?.length) {
      const filteredAreas = allArea?.filter(item => item?.name === property?.name);
      setselectedArea(filteredAreas?.[0]);
    }
  }, [isPunePlant, property,flat,allArea]);

  useEffect(() => {
    handleGetAllArea();
  }, [page, searchKey]);

  return (
    <Grid container item xs={12}>
      <Autocomplete
        multiple={false}
        onChange={(e, value) => {
          setselectedArea(value);
        }}
        id="area-search"
        style={{
          width: "98%",
          backgroundColor: "#fff",
        }}
        freeSolo
        value={selectedArea}
        disabled={(isPunePlant && flat?.name==="Toilet")?true:false}
        classes={classes}
        options={allArea}
        autoHighlight
        getOptionLabel={(option) => `${option?._id ? option.name : ""}`}
        renderOption={(props, option) => {
          return (
            <div>
              <li {...props} key={option._id}>
                {selectedArea?.length > 0 && selectedArea.includes(option) && (
                  <CheckCircle
                    style={{
                      color: "rgb(36 202 38)",
                      fontSize: "14px",
                      marginRight: "5px",
                    }}
                  />
                )}
                {option?.name}
              </li>
            </div>
          );
        }}
        renderInput={(params) => {
          return (
            <TextField
              required
              {...params}
              onChange={(e) => {
                setpage(1);
                setsearchKey(e.target.value);
              }}
              onBlur={() => {
                setsearchKey("");
              }}
              label={loading ? "Loading..." : `Select Area (${totalCount})`}
              inputProps={{
                ...params.inputProps,
              }}
            />
          );
        }}
        ListboxProps={{
          onScroll: handleScroll,
        }}
      />
    </Grid>
  );
}

export default ChooseAreaDropDown;
