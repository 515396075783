import {
  Typography,
  Paper,
  Grid,
  CssBaseline,
  CardHeader,
  CardMedia,
  CardContent,
  Avatar,
  Badge,
  Fab,
  Button,
  Card,
  List,
  ListItem,
  Modal,
} from "@material-ui/core";
import React, { useState } from "react";
import useStyles from "./style";
import { useNavigate, useParams } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import * as api from "../../api";
import { useEffect } from "react";
import { BASE_URL } from "../../utils/base";
import { AuthContext } from "../../contextAPI/context";
import { useContext } from "react";
import moment from "moment";
import { Tag } from "antd";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import EditNotice from "./EditNotice/EditNotice";
import { Apartment } from "@material-ui/icons";
import InfoIcon from "@mui/icons-material/Info";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { handleGetCheckModulePermission } from "../../contextAPI/checkModulesPermission";
import DeleteIcon from "@mui/icons-material/Delete";
import { QuestionMark } from "@mui/icons-material";
import { toast } from "react-toastify";
import { resolveErrorMessage } from "../../services/service";
import { handlePropertyName } from "../../contextAPI/NamingConvention";
import { navigateInitialNotice } from "../../Navigation/Navigations";
import BanterLoader from "../../GlobalComponents/Loaders/BanterLoader";
import DownloadImageModal from "../DownloadImageModal/DownloadImageModal";

function IndividualNotice() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const { user, usermoduleswithpermissions, plant } = authState;
  const { id } = useParams();
  const [notice, setNotice] = useState([]);
  const [pleasewait, setpleasewait] = useState(false);
  const [loader, setloader] = useState(false);
  const [editNotice, seteditNotice] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setopenModal] = useState(false);
  const [isReadWrite, setIsReadWrite] = useState(false);
  const [confirmDeleteNotice, setconfirmDeleteNotice] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const defaultImage =
    "https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png";

  const confirmModalDeleteNotice = (
    <div>
      {confirmDeleteNotice && (
        <Modal open={confirmDeleteNotice}>
          <Paper
            style={{
              backgroundColor: "#e6e7f0",
              // minWidth: "250px",
              // maxWidth: "450px",
            }}
            className={classes.confirmModal}
          >
            <Typography
              style={{
                color: "#6c757d",
                fontSize: 19,
                textAlign: "center",
                fontWeight: "bold",
                fontFamily: "Montserrat, sans-serif",
                marginBottom: 7,
                textTransform: "uppercase",
              }}
            >
              ARE YOU SURE YOU WANT TO DELETE THIS NOTICE ?
            </Typography>
            <Typography style={{ textAlign: "center" }}>
              <QuestionMark style={{ fontSize: 70, color: "#1e2082" }} />
            </Typography>

            <Typography
              style={{
                color: "#6c757d",
                fontSize: 14,
                textAlign: "center",
                fontFamily: "Montserrat, sans-serif",
                marginTop: 5,
              }}
            >
              A confirmation message will appear when this process is
              complete.You can confirm by clicking below.
            </Typography>
            {loader && <BanterLoader />}
            <Grid container style={{ justifyContent: "space-evenly" }}>
              <Button
                onClick={() => {
                  setconfirmDeleteNotice(false);
                  setloader(false);
                }}
                style={{
                  backgroundColor: "#C60C30",
                  color: "#fff",
                  marginTop: "12px",
                  padding: 8,
                  width: 180,
                }}
              >
                CANCEL
              </Button>
              <Button
                onClick={() => {
                  handleDeleteNotice();
                }}
                style={{
                  backgroundColor: "#1e2082",
                  color: "#fff",
                  marginTop: "12px",
                  padding: 8,
                  width: 180,
                }}
              >
                CONFIRM
              </Button>
            </Grid>
          </Paper>
        </Modal>
      )}
    </div>
  );

  useEffect(async () => {
    if (usermoduleswithpermissions) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Notice"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  const handleCloseDialog = () => {
    setopenModal(false);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleIndivisualNotice = async () => {
    setpleasewait(true);
    setTimeout(() => {
      setpleasewait(false);
    }, 40000);
    try {
      const sendData = {
        noticeId: id,
        userId: user?._id,
      };
      const res = await api.getAllNoticeById(sendData);
      if (res?.data?.success) {
        setNotice(res?.data?.notice);
      }
      setpleasewait(false);
    } catch (error) {
      setpleasewait(false);
      resolveErrorMessage(error);
    }
  };

  useEffect(() => {
    handleIndivisualNotice();
  }, []);

  const handleDeleteNotice = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        noticeId: id,
        userId: user?._id,
      };
      const res = await api.deleteNotice(sendData);
      if (res?.data?.success) {
        toast.success("Notice Deleted Successfully");
        navigate(navigateInitialNotice({}));
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  const handledownloadAttachment = async (attachment) => {
    let attch = attachment.split("/");
    try {
      await api.downloadAttachments(attch[1]);
      await api.downloadImages(attch[1]);
    } catch (error) {}
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid item sx={12} sm={12} lg={12}>
            <Paper
              style={{
                padding: 20,
                boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <Grid container className="ms-1 justify-content-start">
                <span
                  className="back-to-home pointer"
                  onClick={() => {
                    navigate(navigateInitialNotice({}));
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 12H19M5 12L11 18M5 12L11 6"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  &nbsp; Back to Home
                </span>
              </Grid>
              <Grid container className="mt-4">
                <span className="page-header">Notice Details</span>
              </Grid>
              {isReadWrite && notice?.isDeleted === false && (
                <Grid container className="mt-1 justify-content-end">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="pointer"
                    onClick={() => {
                      seteditNotice(true);
                      handleClose();
                    }}
                  >
                    <path
                      d="M27.6215 15.8861C27.2615 15.8861 26.9669 16.1807 26.9669 16.5407V31.569C26.9669 32.3872 26.2993 33.0483 25.4811 33.0483H4.43094C3.61276 33.0483 2.95167 32.3807 2.95167 31.569V10.5188C2.95167 9.70065 3.61931 9.03956 4.43094 9.03956H19.4528C19.8128 9.03956 20.1073 8.74501 20.1073 8.38501C20.1073 8.02501 19.8128 7.73047 19.4528 7.73047H4.43094C2.89276 7.73047 1.64258 8.98065 1.64258 10.5188V31.5756C1.64258 33.1137 2.89276 34.3639 4.43094 34.3639H25.4877C27.0259 34.3639 28.2826 33.1137 28.2826 31.5756V16.5407C28.276 16.1807 27.9815 15.8861 27.6215 15.8861Z"
                      fill="#979797"
                    />
                    <path
                      d="M33.801 3.94038L32.0599 2.19928C31.3137 1.4531 30.0962 1.4531 29.3435 2.19928L18.609 12.9338C18.537 13.0058 18.4781 13.0975 18.4519 13.1956L16.7566 18.4189C16.6781 18.6546 16.7435 18.9098 16.9137 19.0866C17.0381 19.2109 17.2082 19.2764 17.3784 19.2764C17.4439 19.2764 17.5159 19.2633 17.5813 19.2436L22.8046 17.5484C22.9028 17.5156 22.9944 17.4633 23.0664 17.3913L33.801 6.65674C34.5537 5.90401 34.5537 4.68656 33.801 3.94038ZM22.6082 15.9971L20.0032 13.392L27.8053 5.58983L30.4104 8.19492L22.6082 15.9971ZM19.3682 14.6095L21.3842 16.6255L18.3995 17.5942L19.3682 14.6095ZM32.8781 5.72728L31.3399 7.26547L28.7348 4.66038L30.273 3.12219C30.5086 2.88656 30.9013 2.88656 31.137 3.12219L32.8781 4.86328C33.1137 5.09892 33.1137 5.4851 32.8781 5.72728Z"
                      fill="#979797"
                    />
                  </svg>

                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="pointer ms-2 me-3"
                    onClick={() => {
                      setconfirmDeleteNotice(true);
                      handleClose();
                    }}
                  >
                    <path
                      d="M6 10.5H30M7.5 10.5L9 28.5C9 29.2956 9.31607 30.0587 9.87868 30.6213C10.4413 31.1839 11.2044 31.5 12 31.5H24C24.7956 31.5 25.5587 31.1839 26.1213 30.6213C26.6839 30.0587 27 29.2956 27 28.5L28.5 10.5M13.5 10.5V6C13.5 5.60218 13.658 5.22064 13.9393 4.93934C14.2206 4.65804 14.6022 4.5 15 4.5H21C21.3978 4.5 21.7794 4.65804 22.0607 4.93934C22.342 5.22064 22.5 5.60218 22.5 6V10.5M15 18L21 24M21 18L15 24"
                      stroke="#979797"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Grid>
              )}

              {!pleasewait ? (
                <div
                  style={{
                    marginTop: 15,
                    marginBottom: 15,
                    borderRadius: "8px",
                    height: "100%",
                    padding: "16px 20px",
                    border: "1px solid #e1e1e1",
                  }}
                >
                  <CardHeader
                    style={{ fontFamily: "Poppins, sans-serif" }}
                    avatar={
                      <Avatar
                        src={
                          notice?.publishedBy?.image
                            ? `${BASE_URL}/user/${notice?.publishedBy?.image}`
                            : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                        }
                      />
                    }
                    action={
                      <div>
                        <Badge style={{ marginRight: 12 }}>
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 40 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.8333 13C15.8333 14.1051 16.2723 15.1649 17.0537 15.9463C17.8351 16.7277 18.8949 17.1667 20 17.1667C21.1051 17.1667 22.1649 16.7277 22.9463 15.9463C23.7277 15.1649 24.1667 14.1051 24.1667 13C24.1667 11.8949 23.7277 10.8351 22.9463 10.0537C22.1649 9.27232 21.1051 8.83333 20 8.83333C18.8949 8.83333 17.8351 9.27232 17.0537 10.0537C16.2723 10.8351 15.8333 11.8949 15.8333 13Z"
                              stroke="#03045E"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M38.75 13C33.75 21.3333 27.5 25.5 20 25.5C12.5 25.5 6.25 21.3333 1.25 13C6.25 4.66667 12.5 0.5 20 0.5C27.5 0.5 33.75 4.66667 38.75 13Z"
                              stroke="#03045E"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          <i
                            style={{
                              fontSize: 18,
                              fontWeight: "bold",
                              color: "#03045e",
                              marginLeft: 5,
                            }}
                          >
                            {notice?.readBy}
                          </i>
                        </Badge>
                        <Badge style={{ marginRight: 10, marginLeft: 5 }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            fill="currentColor"
                            class="bi bi-eye-slash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                            <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                          </svg>
                          <i
                            style={{
                              fontSize: 18,
                              fontWeight: "bold",
                              color: "#03045e",
                              marginLeft: 5,
                            }}
                          >
                            {notice?.unreadBy}
                          </i>
                        </Badge>
                      </div>
                    }
                    title={
                      <Grid
                        container
                        style={{ justifyContent: "space-between" }}
                      >
                        <Grid item>{notice?.publishedBy?.name}</Grid>
                        <Grid item>
                          {notice?.isDeleted === false && (
                            <>
                              {notice?.isPublished === "published" ? (
                                <Tag
                                  style={{
                                    cursor: "pointer",
                                    padding: 6,
                                    width: "105px",
                                    fontSize: "11px",
                                    fontWeight: "bold",
                                    marginLeft: 10,
                                    marginTop: -8,
                                  }}
                                  color="green"
                                  icon={
                                    <PublishedWithChangesIcon
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                        marginRight: 4,
                                      }}
                                    />
                                  }
                                >
                                  PUBLISHED
                                </Tag>
                              ) : (
                                <Tag
                                  style={{
                                    cursor: "pointer",
                                    padding: 6,
                                    width: "75px",
                                    fontSize: "11px",
                                    fontWeight: "bold",
                                    marginLeft: 10,

                                    marginTop: -8,
                                  }}
                                  color="blue"
                                  icon={
                                    <SaveAsIcon
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                        marginRight: 4,
                                      }}
                                    />
                                  }
                                >
                                  Draft
                                </Tag>
                              )}
                            </>
                          )}

                          {notice?.isDeleted === true && (
                            <Tag
                              style={{
                                cursor: "pointer",
                                padding: 6,
                                width: "80px",
                                fontSize: "11px",
                                fontWeight: "bold",
                                marginLeft: 10,

                                marginTop: -8,
                              }}
                              color="red"
                              icon={
                                <DeleteIcon
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    marginRight: 4,
                                  }}
                                />
                              }
                            >
                              Deleted
                            </Tag>
                          )}
                        </Grid>
                        <Grid item>
                          <Typography></Typography>
                        </Grid>
                      </Grid>
                    }
                    subheader={
                      <Typography
                        style={{
                          marginTop: -6,
                          fontSize: 13,
                          color: "#6c757d",
                        }}
                      >
                        {moment(notice?.createdAt).format(
                          "DD MMM, YYYY, HH:mm"
                        )}
                      </Typography>
                    }
                  />

                  {notice?.images?.length > 0 ? (
                    <Typography
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        fontWeight: "bolder",
                        marginTop: 15,
                        fontSize: 18,
                        marginBottom: 15,
                        textAlign: "center",
                      }}
                    >
                      {notice?.images?.length} Images of this notice
                    </Typography>
                  ) : (
                    <Grid
                      container
                      style={{ justifyContent: "center", boxShadow: "none" }}
                    >
                      <Card>
                        <CardMedia
                          component="img"
                          style={{
                            width: "100%",
                            height: "200px",
                            border: "none",
                            objectFit: "cover",
                            /*  marginLeft: 550, */
                          }}
                          image={
                            "https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png"
                          }
                        />
                      </Card>
                    </Grid>
                  )}

                  {notice?.images?.length > 0 ? (
                    <Carousel
                      items={notice?.images}
                      style={{ color: "#2f45c5" }}
                    >
                      {notice?.images?.map((item) => (
                        <CardMedia
                          component="img"
                          style={{
                            objectFit: "contain",
                            maxWidth: "60%",
                            maxHeight: 400,
                          }}
                          image={
                            item
                              ? item
                                ? item
                                : "https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png"
                              : "https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png"
                          }
                          alt="image"
                          onClick={() => setOpenImageModal(true)}
                        />
                      ))}
                    </Carousel>
                  ) : null}

                  <CardContent>
                    <Typography
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        fontWeight: "bold",
                        marginBottom: 15,
                        marginTop: 15,
                        textAlign: "center",
                        fontSize: 16,
                        color: "#2f45c5",
                      }}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.25 14.75H14.75M2.75 14.75V4.25L8.75 1.25V14.75M13.25 14.75V7.25L8.75 4.25M5.75 5.75V5.7575M5.75 8V8.0075M5.75 10.25V10.2575M5.75 12.5V12.5075"
                          stroke="#5E5E5E"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      {notice?.propertyName?.length > 0
                        ? notice?.propertyName?.length +
                          `Selected ${handlePropertyName()}`
                        : plant
                        ? `Plant Wise`
                        : `Society Wise`}
                      {notice?.propertyName?.length > 0 && (
                        <InfoIcon
                          title="Porperty Info"
                          style={{
                            color: "#2f45c5",
                            cursor: "pointer",
                            marginLeft: 10,
                            fontSize: 20,
                            marginTop: -4,
                          }}
                          onClick={() => {
                            setopenModal(true);
                          }}
                        />
                      )}
                    </Typography>
                    <Typography
                      variant="h4"
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        fontWeight: "bold",
                        marginBottom: 15,
                        marginTop: 15,
                        textAlign: "center",
                      }}
                    >
                      {notice?.noticeTitle}
                    </Typography>
                    <div style={{ textAlign: "center", marginBottom: 8 }}>
                      <Tag color="blue">
                        <Typography
                          style={{
                            fontFamily: "Montserrat, sans-serif",
                            fontSize: 18,
                            fontWeight: "bold",
                          }}
                        >
                          {notice?.noticeType?.name}
                        </Typography>
                      </Tag>
                    </div>

                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        justifyContent: "flex-start",
                        padding: 5,
                      }}
                    >
                      {notice?.noticeDescription}
                    </Typography>
                  </CardContent>

                  {notice?.attachments?.length > 0 ? (
                    <Typography
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        fontWeight: "bolder",
                        marginTop: 15,
                        fontSize: 18,
                        marginBottom: 15,
                        textAlign: "center",
                      }}
                    >
                      Attachments of this notice
                    </Typography>
                  ) : (
                    <Typography
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        fontWeight: "bolder",
                        marginTop: 15,
                        fontSize: 18,
                        marginBottom: 15,
                        textAlign: "center",
                      }}
                    >
                      No Attachments Available
                    </Typography>
                  )}

                  <Grid container style={{ justifyContent: "center" }}>
                    {notice?.attachments?.map((attc, index) => {
                      return (
                        <a
                          style={{
                            marginRight: 10,
                          }}
                          color="#2f3aa3"
                          href={attc}
                          download
                          target="_blank"
                        >
                          <Fab
                            variant="extended"
                            size="medium"
                            style={{
                              backgroundColor: "#2f3aa3",
                              color: "#fff",
                              padding: "6px",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-download"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                            </svg>
                            &nbsp;
                            <Typography
                              style={{
                                textTransform: "lowercase",
                                fontSize: 11,
                              }}
                            >
                              Attachment-{index+1}
                            </Typography>
                          </Fab>
                        </a>
                      );
                    })}
                  </Grid>
                </div>
              ) : (
                <BanterLoader />
              )}
            </Paper>
          </Grid>
        </Grid>
        <EditNotice
          editNotice={editNotice}
          seteditNotice={seteditNotice}
          notice={notice}
          handleIndivisualNotice={handleIndivisualNotice}
        />
        <Dialog
          open={openModal}
          keepMounted
          onClose={handleCloseDialog}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 13,
                fontWeight: "bolder",
                marginBottom: 10,
                display: "flex",
              }}
            >
              Notice Selected {handlePropertyName()} Details
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container style={{ justifyContent: "space-between" }}>
              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  fontSize: 15,
                  marginBottom: 5,
                }}
              >
                Name
              </Typography>

              <Typography
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  fontSize: 15,
                  marginRight: 20,
                  marginBottom: 5,
                  marginLeft: 20,
                }}
              >
                Sector
              </Typography>
            </Grid>
            <Grid>
              <List>
                {notice?.propertyName?.length > 0 ? (
                  <>
                    {notice?.propertyName?.map((property) => {
                      return (
                        <ListItem>
                          <Apartment
                            style={{
                              fontSize: 15,
                              color: "#6c757d",
                              marginRight: 4,
                              marginLeft: -17,
                            }}
                          />
                          <Grid
                            container
                            style={{ justifyContent: "space-between" }}
                          >
                            <Typography style={{ fontSize: 15 }}>
                              {property?.name}
                            </Typography>
                            <Typography style={{ fontSize: 15 }}>
                              {property?.sector}
                            </Typography>
                          </Grid>
                        </ListItem>
                      );
                    })}
                  </>
                ) : (
                  <Grid container style={{ justifyContent: "center" }}>
                    <Typography
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        color: "#6c757d",
                        fontSize: 15,
                        marginBottom: 5,
                      }}
                    >
                      No {handlePropertyName()} Selected Yet
                    </Typography>
                  </Grid>
                )}
              </List>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Close</Button>
          </DialogActions>
        </Dialog>

        {openImageModal && (
          <DownloadImageModal
            notice={notice}
            setOpenImageModal={setOpenImageModal}
            defaultImage={defaultImage}
          />
        )}
      </main>
      {confirmModalDeleteNotice}
      {loader && <BanterLoader />}
    </div>
  );
}

export default IndividualNotice;
