import {
  CssBaseline,
  Typography,
  Grid,
  Button,
  Paper,
  Avatar,
  CardContent,
  Tooltip,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contextAPI/context";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import useStyles from "../Discussion/AllDiscussions/style";
import * as api from "../../api/index";
import { BASE_URL } from "../../utils/base";
import moment from "moment";

import InfoIcon from "@mui/icons-material/Info";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Add,
  Attachment,
  ExpandLess,
  ExpandMore,
  Reply,
} from "@material-ui/icons";
import { Input } from "antd";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { resolveErrorMessage } from "../../services/service";
import { LoadingOutlined } from "@ant-design/icons";
import { handleGetCheckModulePermission } from "../../contextAPI/checkModulesPermission";
import { navigateInitialDiscussions } from "../../Navigation/Navigations";
import DeleteDiscussion from "./AddDiscussions.jsx/DeleteDiscussion";
import ViwersAndPropertyModal from "./AddDiscussions.jsx/ViwersAndPropertyModal";
import BanterLoader from "../../GlobalComponents/Loaders/BanterLoader";

export default function IndividualDiscussions() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { TextArea } = Input;
  const { id } = useParams();
  const { authState } = useContext(AuthContext);
  const [discussions, setDiscussions] = useState([]);
  const [openReplies, setopenReplies] = useState(false);
  const { user, usermoduleswithpermissions } = authState;
  const [errorMessage, seterrorMessage] = useState("");
  const [comment, setcomment] = useState("");
  const [reply, setreply] = useState("");
  const [individualComment, setIndividualComment] = useState([]);
  const [addReply, setaddReply] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [discussionAllComments, setdiscussionAllComments] = useState([]);
  const [isReadWrite, setIsReadWrite] = useState(false);
  const [loader, setloader] = useState(false);
  const [otherInfo, setotherInfo] = useState([]);
  const [openOtherInfoModal, setopenOtherInfoModal] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [otherInfoKey, setotherInfoKey] = useState("");
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [confirmDeleteDiscussion, setconfirmDeleteDiscussion] = useState(false);
  const [commentLoading, setcommentLoading] = useState(false);
  const [replyLoading, setreplyLoading] = useState(false);

  const handleCloseDialog = () => {
    setopenOtherInfoModal(false);
  };

  useEffect(async () => {
    if (usermoduleswithpermissions?.length) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Discussion"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleGetDiscussionsById = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendData = {
        societyId: fetchSocietyId(),
        discussionId: id,
        userId: user._id,
      };
      const res = await api.getallDiscussionsByID(sendData);
      if (res?.data?.success) {
        setDiscussions(res?.data?.discussion);
        const temp = [...res?.data?.discussion?.commentSection]?.reverse();
        setdiscussionAllComments(temp);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };
  const handleAddComment = async () => {
    setcommentLoading(true);
    setTimeout(() => {
      setcommentLoading(false);
    }, 40000);
    try {
      const sendData = {
        discussionId: id,
        commentorId: user?._id,
        comment: comment,
        tags: [],
      };
      const res = await api.addComment(sendData);
      if (res?.data?.success) {
        setcomment("");
        handleGetDiscussionsById();
        setcommentLoading(false);
        toast.success("Added Comment Successfully");
      }
    } catch (error) {
      setcommentLoading(false);
      seterrorMessage(resolveErrorMessage(error));
    }
  };

  const handleAddReply = async () => {
    setreplyLoading(true);
    setTimeout(() => {
      setreplyLoading(false);
    }, 40000);
    try {
      const sendData = {
        discussionId: id,
        commentId: individualComment._id,
        replierId: user?._id,
        reply,
      };
      const res = await api.addReply(sendData);
      if (res?.data?.success) {
        setreply("");
        setaddReply(false);
        let temp = [...res?.data?.discussion?.commentSection]?.reverse();
        setdiscussionAllComments(temp);
        setreplyLoading(false);
        toast.success("Added Replied Successfully");
      }
    } catch (error) {
      setreplyLoading(false);
      seterrorMessage(resolveErrorMessage(error));
    }
  };
  useEffect(() => {
    handleGetDiscussionsById();
  }, []);

  const handleDiscussionMoreInfo = async (key) => {
    setotherInfoKey(key);
    setloader2(true);
    setTimeout(() => {
      setloader2(false);
    }, 40000);
    try {
      const sendData = {
        discussionId: id,
        key,
      };
      const res = await api.discussionOtherInfo(sendData);
      if (res?.data?.success) {
        setotherInfo(res?.data?.result);
        setopenOtherInfoModal(true);
        setloader2(false);
      }
    } catch (error) {
      setloader2(false);
      resolveErrorMessage(error);
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          <Grid item sx={12} sm={12} lg={12}>
            <Paper
              style={{
                padding: 12,
                boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <Grid container className="ms-1 justify-content-start">
                {" "}
                <span
                  className="back-to-home pointer"
                  onClick={() => {
                    navigate(navigateInitialDiscussions({}));
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 12H19M5 12L11 18M5 12L11 6"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  &nbsp; Back to Home
                </span>
              </Grid>
              <Grid container className="mt-4">
                <Grid>
                  <span className="page-header">
                    Discussion Details
                    {!loader ? (
                      <RefreshIcon
                        title="refresh Discussion Details Page"
                        onClick={handleGetDiscussionsById}
                        style={{
                          marginLeft: 5,
                          marginTop: -4,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{ marginLeft: 5, color: "#000", marginTop: -4 }}
                      />
                    )}
                  </span>
                </Grid>
              </Grid>

              <div className="discussion-card mt-2">
                <div className="col-sm-12 d-flex mt-2">
                  <div className="col-sm-5 d-flex">
                    <Avatar
                      src={
                        discussions?.addedBy?.image
                          ? `${BASE_URL}/user/${discussions?.addedBy?.image}`
                          : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                      }
                      className="discussion-profile-pic ms-3 d-flex justify-content-center align-items-center "
                    />

                    <div className="d-flex flex-column ms-2">
                      <div className="discussion-admin-name">
                        {discussions?.addedBy?.name}
                      </div>
                      <div className="discussion-post-date">
                        {moment(discussions?.createdAt).format(
                          "DD/MM/YYYY - h:mm A"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-5">
                    {discussions?.attachments?.length > 0 && (
                      <>
                        <Typography
                          style={{
                            fontWeight: "bolder",
                            fontSize: 15,
                            fontFamily: "Montserrat, sans-serif",
                            color: "#000",
                            marginBottom: 10,
                          }}
                        >
                          All Attachments
                        </Typography>
                        {discussions?.attachments?.map((attc, index) => {
                          return (
                            <Grid
                              container
                              className="d-flex justify-content-start"
                            >
                              <Tooltip title={attc?.key} placement="top" arrow>
                                <Typography style={{ marginRight: 10 }}>
                                  <Attachment />
                                  &nbsp;
                                  {attc?.key?.length > 25
                                    ? attc?.key?.slice(0, 25).concat("...")
                                    : attc?.key}
                                </Typography>
                              </Tooltip>

                              <a
                                href={attc}
                                target="_blank"
                                style={{
                                  color: "#2f45c5",
                                  cursor: "pointer",
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                }}
                              >
                                <svg
                                  width="15"
                                  height="15"
                                  viewBox="0 0 18 19"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1 14V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H15C15.5304 18 16.0391 17.7893 16.4142 17.4142C16.7893 17.0391 17 16.5304 17 16V14M4 8L9 13M9 13L14 8M9 13V1"
                                    stroke="#03045E"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                &nbsp;
                                <span className="text-decoration-underline">
                                  Download
                                </span>
                              </a>
                            </Grid>
                          );
                        })}
                      </>
                    )}
                  </div>
                  <div className="col-sm-2 d-flex justify-content-end">
                    <div
                      className="discussion-edit-icon me-3"
                      onClick={() => {
                        setOpen(false);
                        setconfirmDeleteDiscussion(true);
                      }}
                    >
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 10.5H30M7.5 10.5L9 28.5C9 29.2956 9.31607 30.0587 9.87868 30.6213C10.4413 31.1839 11.2044 31.5 12 31.5H24C24.7956 31.5 25.5587 31.1839 26.1213 30.6213C26.6839 30.0587 27 29.2956 27 28.5L28.5 10.5M13.5 10.5V6C13.5 5.60218 13.658 5.22064 13.9393 4.93934C14.2206 4.65804 14.6022 4.5 15 4.5H21C21.3978 4.5 21.7794 4.65804 22.0607 4.93934C22.342 5.22064 22.5 5.60218 22.5 6V10.5M15 18L21 24M21 18L15 24"
                          stroke="#979797"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 d-flex">
                  <div className="col-sm-6">
                    <div className="discussion-title ms-2 mt-2">
                      {discussions?.discussionTitle}
                    </div>
                    <div className="discussion-image d-flex justify-content-center align-items-center mt-5">
                      <img
                        src={
                          discussions?.images?.length > 0
                            ? discussions?.images[0]
                            : "https://www.ncenet.com/wp-content/uploads/2020/04/No-image-found.jpg"
                        }
                        alt="Sociohood"
                      />
                    </div>

                    <div className="discussion-text mt-2 p-3">
                      <p className="ms-2">
                        {discussions?.discussionDescription}
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="col-sm-12 d-flex justify-content-center align-items-center">
                      <div className="discussion-comments-count d-flex justify-content-center align-items-center ">
                        <svg
                          width="34"
                          height="34"
                          viewBox="0 0 50 50"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.6667 18.7497H33.3333M16.6667 27.083H29.1667M37.5 8.33301C39.1576 8.33301 40.7473 8.99149 41.9194 10.1636C43.0915 11.3357 43.75 12.9254 43.75 14.583V31.2497C43.75 32.9073 43.0915 34.497 41.9194 35.6691C40.7473 36.8412 39.1576 37.4997 37.5 37.4997H27.0833L16.6667 43.7497V37.4997H12.5C10.8424 37.4997 9.25269 36.8412 8.08058 35.6691C6.90848 34.497 6.25 32.9073 6.25 31.2497V14.583C6.25 12.9254 6.90848 11.3357 8.08058 10.1636C9.25269 8.99149 10.8424 8.33301 12.5 8.33301H37.5Z"
                            stroke="#03045E"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        &nbsp;{" "}
                        <span className="text-decoration-underline">
                          {discussions?.commentSection?.length
                            ? discussions?.commentSection?.length + " comments"
                            : "No Comments"}
                        </span>
                      </div>
                      <div className="discussion-views-count d-flex">
                        <svg
                          width="34"
                          height="34"
                          viewBox="0 0 50 50"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20.8333 25C20.8333 26.1051 21.2723 27.1649 22.0537 27.9463C22.8351 28.7277 23.8949 29.1667 25 29.1667C26.1051 29.1667 27.1649 28.7277 27.9463 27.9463C28.7277 27.1649 29.1667 26.1051 29.1667 25C29.1667 23.8949 28.7277 22.8351 27.9463 22.0537C27.1649 21.2723 26.1051 20.8333 25 20.8333C23.8949 20.8333 22.8351 21.2723 22.0537 22.0537C21.2723 22.8351 20.8333 23.8949 20.8333 25Z"
                            stroke="#03045E"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M43.75 25C38.75 33.3333 32.5 37.5 25 37.5C17.5 37.5 11.25 33.3333 6.25 25C11.25 16.6667 17.5 12.5 25 12.5C32.5 12.5 38.75 16.6667 43.75 25Z"
                            stroke="#03045E"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        &nbsp;
                        <span className="text-decoration-underline">
                          Seen by {discussions?.viewedBy?.length} &nbsp;
                          {discussions?.viewedBy?.length > 0 && (
                            <InfoIcon
                              onClick={() => {
                                handleDiscussionMoreInfo("viewers");
                              }}
                              style={{ color: "#2f45c5", cursor: "pointer" }}
                            />
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="discussion-comments-section overflow-auto scrollable">
                      {discussions?.commentSection?.length === 0 && (
                        <div className="col-sm-12 h-100 d-flex justify-content-center align-items-center">
                          <Typography
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: 19,
                              color: "#6c757d",
                              marginTop: 15,
                              marginBottom: 15,
                              fontFamily: "Montserrat, sans-serif",
                            }}
                          >
                            NO COMMENTS
                          </Typography>
                        </div>
                      )}

                      {discussions?.commentSection?.length > 0 &&
                        discussionAllComments?.map((com) => {
                          return (
                            <Grid
                              container
                              wrap="nowrap"
                              className="mt-3"
                            >
                              <Grid item>
                                <Avatar
                                  alt="Remy Sharp"
                                  style={{
                                    marginTop: 10,
                                    marginLeft: 25,
                                    marginRight: 25,
                                  }}
                                  src={
                                    com?.commentor?.image
                                      ? `${BASE_URL}/user/${com?.commentor?.image}`
                                      : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                  }
                                />
                              </Grid>
                              <Grid
                                item
                                xs
                                zeroMinWidth
                                justifyContent={"center"}
                              >
                                <div className="discussion-comment-box">
                                  <CardContent>
                                    <h4
                                      style={{
                                        margin: 0,
                                        textAlign: "left",
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        fontFamily: "Poppins, sans-serif",
                                        marginBottom: 10,
                                      }}
                                    >
                                      {com?.commentor?._id === user?._id
                                        ? "You"
                                        : com?.commentor?.name}
                                      <span
                                        style={{
                                          textAlign: "left",
                                          color: "#343a40",
                                          fontSize: 11,
                                          fontWeight: "normal",
                                          fontFamily: "Poppins, sans-serif",
                                          marginLeft: 10,
                                        }}
                                      >
                                        {"Posted " +
                                          moment(com?.time).fromNow()}
                                      </span>
                                    </h4>
                                    <p
                                      style={{
                                        textAlign: "left",
                                        fontSize: 16,
                                        fontFamily: "Poppins, sans-serif",
                                      }}
                                    >
                                      {showMore
                                        ? com?.comment
                                        : com?.comment?.slice(0, 325)}
                                      {com?.comment.length > 325 ? (
                                        <span
                                          style={{
                                            color: "#4361ee",
                                            marginLeft: 2,
                                            cursor: "pointer",
                                            fontSize: 12,
                                            fontWeight: "bold",
                                          }}
                                          onClick={() => setShowMore(!showMore)}
                                        >
                                          {showMore
                                            ? "see less"
                                            : "...see more"}
                                          {showMore ? (
                                            <ExpandLess />
                                          ) : (
                                            <ExpandMore />
                                          )}
                                        </span>
                                      ) : null}
                                    </p>

                                    <Grid container>
                                      <Typography
                                        style={{
                                          backgroundColor: com?.replies?.length
                                            ? "#fff"
                                            : "#c8c8c8",
                                          color: com?.replies?.length
                                            ? "#4361ee"
                                            : "#ffff",
                                          borderRadius: 8,
                                          padding: 5,
                                          fontSize: 12,
                                          marginTop: 10,
                                          cursor: "pointer",
                                          fontFamily: "Montserrat, sans-serif",
                                          fontWeight: "bold",
                                          paddingLeft: 8,
                                        }}
                                        onClick={() => {
                                          if (com?.replies?.length) {
                                            setopenReplies(!openReplies);
                                            setIndividualComment(com);
                                            setaddReply(false);
                                          }
                                        }}
                                      >
                                        {com?.replies?.length
                                          ? com?.replies?.length + " Replies "
                                          : "No Replies"}{" "}
                                        {openReplies &&
                                        individualComment._id === com._id &&
                                        com?.replies?.length ? (
                                          <ExpandMore
                                            style={{
                                              fontSize: 18,
                                              fontWeight: "bold",
                                            }}
                                          />
                                        ) : (
                                          <Reply style={{ fontSize: 18 }} />
                                        )}
                                      </Typography>
                                      {!discussions?.isDeleted && (
                                        <Typography
                                          style={{
                                            backgroundColor: "#fff",
                                            color: "#4361ee",
                                            borderRadius: 8,
                                            padding: 5,
                                            fontSize: 12,
                                            marginTop: 10,
                                            cursor: "pointer",
                                            marginLeft: 10,
                                            fontFamily:
                                              "Montserrat, sans-serif",
                                            fontWeight: "bold",
                                            paddingLeft: 8,
                                          }}
                                          onClick={() => {
                                            setIndividualComment(com);
                                            setaddReply(!addReply);
                                          }}
                                        >
                                          Add Reply{" "}
                                          {addReply &&
                                          individualComment._id === com._id ? (
                                            <ExpandMore
                                              style={{
                                                fontSize: 18,
                                                fontWeight: "bold",
                                              }}
                                            />
                                          ) : (
                                            <Add style={{ fontSize: 18 }} />
                                          )}
                                        </Typography>
                                      )}
                                    </Grid>
                                  </CardContent>
                                </div>
                                {addReply &&
                                  individualComment._id === com._id && (
                                    <Grid style={{ padding: 10 }}>
                                      <Grid
                                        container
                                        item
                                        xs={12}
                                        style={{
                                          marginBottom: 10,
                                        }}
                                      >
                                        <TextArea
                                          rows={3}
                                          value={reply}
                                          onChange={(event) => {
                                            setreply(event.target.value);
                                          }}
                                          style={{
                                            borderRadius: "16px",
                                          }}
                                          placeholder="Write your reply here"
                                          maxLength={220}
                                        />
                                      </Grid>
                                      <Grid
                                        container
                                        item
                                        style={{
                                          justifyContent: "flex-end",
                                          marginBottom: 25,
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          size="small"
                                          style={{
                                            backgroundColor: ` ${
                                              reply === "" || !isReadWrite
                                                ? "#979797"
                                                : "#3862dd"
                                            }`,
                                            color: "#fff",
                                            marginRight: 10,
                                          }}
                                          disabled={
                                            reply === "" ||
                                            !isReadWrite ||
                                            replyLoading
                                          }
                                          onClick={handleAddReply}
                                        >
                                          {isReadWrite ? (
                                            replyLoading ? (
                                              <LoadingOutlined
                                                style={{
                                                  color: "#fff",
                                                  fontSize: "25px",
                                                }}
                                              />
                                            ) : (
                                              "Reply"
                                            )
                                          ) : (
                                            "Permission Denied"
                                          )}
                                        </Button>
                                        <Button
                                          variant="contained"
                                          size="small"
                                          onClick={() => {
                                            setaddReply(false);
                                          }}
                                          disabled={replyLoading}
                                          style={{
                                            backgroundColor: ` ${
                                              replyLoading
                                                ? "#979797"
                                                : "#3862dd"
                                            }`,
                                            color: "#fff",
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  )}
                                {openReplies &&
                                  individualComment._id === com._id && (
                                    <div
                                      container
                                      wrap="nowrap"
                                      spacing={2}
                                      className="discussion-comment d-flex flex-column"
                                    >
                                      {com?.replies?.map((rep) => {
                                        var time = time;
                                        return (
                                          <div className="col-sm-12 d-flex">
                                            <div className="col-sm-2">
                                              <Avatar
                                                alt="Remy Sharp"
                                                style={{
                                                  marginTop: 18,
                                                  marginLeft: 15,
                                                  marginRight: 15,
                                                }}
                                                src={
                                                  rep?.replier?.image
                                                    ? `${BASE_URL}/user/${rep?.replier?.image}`
                                                    : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                                }
                                              />
                                            </div>
                                            <div
                                              className="col-sm-10 discussion-comment-reply-box mt-2"
                                              zeroMinWidth
                                              justifyContent={"left"}
                                            >
                                              <CardContent>
                                                <h4
                                                  style={{
                                                    margin: 0,
                                                    textAlign: "left",
                                                    fontSize: 16,
                                                    fontWeight: "bold",
                                                    fontFamily:
                                                      "Poppins, sans-serif",
                                                    marginBottom: 10,
                                                  }}
                                                >
                                                  {rep?.replier?._id ===
                                                  user?._id
                                                    ? "You"
                                                    : rep?.replier?.name}{" "}
                                                  &nbsp;
                                                  <span
                                                    style={{
                                                      textAlign: "left",
                                                      color: "#343a40",
                                                      fontSize: 11,
                                                      fontWeight: "normal",
                                                      fontFamily:
                                                        "Poppins, sans-serif",
                                                      marginLeft: 10,
                                                    }}
                                                  >
                                                    {moment(
                                                      rep?.time
                                                    ).fromNow()}
                                                  </span>
                                                </h4>
                                                <p
                                                  style={{
                                                    textAlign: "left",
                                                    fontSize: 16,
                                                    fontFamily:
                                                      "Poppins, sans-serif",
                                                  }}
                                                >
                                                  {showMore
                                                    ? rep?.reply
                                                    : rep?.reply?.slice(0, 100)}
                                                  {rep?.reply?.length > 105 ? (
                                                    <span
                                                      style={{
                                                        color: "#4361ee",
                                                        marginLeft: 10,
                                                        cursor: "pointer",
                                                        fontSize: 10,
                                                        fontWeight: "bold",
                                                      }}
                                                      onClick={() =>
                                                        setShowMore(!showMore)
                                                      }
                                                    >
                                                      {showMore
                                                        ? "Show less"
                                                        : "Show more"}
                                                    </span>
                                                  ) : null}
                                                </p>
                                              </CardContent>
                                            </div>
                                          </div>
                                        );
                                      })}
                                      {openReplies &&
                                        !addReply &&
                                        individualComment._id === com._id && (
                                          <Grid style={{ padding: 10 }}>
                                            <Grid
                                              container
                                              item
                                              xs={12}
                                              style={{
                                                marginBottom: 10,
                                              }}
                                            >
                                              <TextArea
                                                rows={3}
                                                value={reply}
                                                onChange={(event) => {
                                                  setreply(event.target.value);
                                                }}
                                                style={{
                                                  borderRadius: "16px",
                                                }}
                                                placeholder="Write your reply here"
                                                maxLength={220}
                                              />
                                            </Grid>
                                            {!discussions?.isDeleted && (
                                              <Grid
                                                container
                                                item
                                                style={{
                                                  justifyContent: "flex-end",
                                                  marginBottom: 25,
                                                }}
                                              >
                                                <Button
                                                  variant="contained"
                                                  color="primary"
                                                  size="small"
                                                  style={{
                                                    backgroundColor: ` ${
                                                      reply === "" ||
                                                      !isReadWrite
                                                        ? "#979797"
                                                        : "#3862dd"
                                                    }`,
                                                    color: "#fff",
                                                    marginRight: 10,
                                                  }}
                                                  disabled={
                                                    reply === "" || !isReadWrite
                                                  }
                                                  onClick={handleAddReply}
                                                >
                                                  {isReadWrite ? (
                                                    replyLoading ? (
                                                      <LoadingOutlined
                                                        style={{
                                                          color: "#fff",
                                                          fontSize: "25px",
                                                        }}
                                                      />
                                                    ) : (
                                                      "Reply"
                                                    )
                                                  ) : (
                                                    "Permission Denied"
                                                  )}
                                                </Button>
                                              </Grid>
                                            )}
                                          </Grid>
                                        )}
                                    </div>
                                  )}
                              </Grid>
                            </Grid>
                          );
                        })}
                    </div>

                    <div className="discussion-comment-input-section d-flex align-items-center justify-content-between ms-5">
                      <TextArea
                        rows={4}
                        value={comment}
                        onChange={(event) => {
                          setcomment(event.target.value);
                        }}
                        placeholder="Write your comment here"
                        maxLength={400}
                        style={{
                          border: "none",
                          height: "98%",
                          borderRadius: "16px",
                          width: "100%",
                          paddingRight: "80px",
                        }}
                        className="discussion-comment-input flex-grow-1 me-3"
                      />
                      <div
                        className="discussion-post-button pointer"
                        type="button"
                        disabled={comment === "" || !isReadWrite}
                        onClick={handleAddComment}
                        style={{
                          color: `${
                            comment === "" || !isReadWrite
                              ? "#979797"
                              : "#3862dd"
                          }`,
                          cursor: "pointer",
                          backgroundColor: "#fff",
                          padding: "5px 10px",
                          borderRadius: "8px",
                        }}
                      >
                        {isReadWrite ? (
                          commentLoading ? (
                            <LoadingOutlined
                              style={{
                                color: "#2f45c5",
                                marginLeft: 15,
                              }}
                            />
                          ) : (
                            "Post"
                          )
                        ) : (
                          "Permission Denied"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
        {openOtherInfoModal && (
          <ViwersAndPropertyModal
            openOtherInfoModal={openOtherInfoModal}
            handleCloseDialog={handleCloseDialog}
            otherInfoKey={otherInfoKey}
            otherInfo={otherInfo}
            loader2={loader2}
          />
        )}
      </main>
      {confirmDeleteDiscussion && (
        <DeleteDiscussion
          open={confirmDeleteDiscussion}
          setOpen={setconfirmDeleteDiscussion}
        />
      )}

      {loader && <BanterLoader />}
    </div>
  );
}
