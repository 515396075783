import { AwsS3Routes } from "../api/Routes";
import ApiCall from "../api/ApiCall";



//PREVIEW FILE INTO LOCAL
export const handlePreviewFileInLocal = (event) => {
  if (
    event &&
    event.target &&
    event.target.files &&
    event.target.files.length > 0
  ) {
    const img = URL.createObjectURL(event.target.files[0]);
    return img;
  } 
};



export const handleGeneratePreSignedURLAWSS3 = async (image) => {
  let params = new URLSearchParams({
    fileType: image?.type,
    fileName: image?.name,
  });
  let res = await ApiCall(
    "get",
    `${AwsS3Routes.getPreSignedURL}?${params.toString()}`
  );
  return res?.directory || null;
};

export const handleUploadImgFile = async (file) => {
  try {
    let mainFile = file.target.files[0];
    const { presignedUrl, fileUrl } = await handleGeneratePreSignedURLAWSS3(
      mainFile
    );
    const uploadResponse = await fetch(presignedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": mainFile.type,
      },
      body: mainFile,
    });

    if (uploadResponse.ok) {
      console.log("File uploaded successfully");
      return fileUrl;
    } else {
      console.error(
        "File upload failed:",
        uploadResponse.status,
        uploadResponse.statusText
      );
      return null;
    }
  } catch (error) {
    console.error("Error uploading file:", error);
    return null;
  }
};
