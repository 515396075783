import {
  Badge,
  Button,
  Card,
  CardMedia,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import { Cancel, ErrorOutline, LocalDiningOutlined } from "@material-ui/icons";
import { Input } from "antd";
import React, { useEffect } from "react";
import { Rating } from "@mui/material";
import { handleUploadInLocal } from "../../../utils/awsUpload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import OtpInput from "otp-input-react";
import { BASE_URL } from "../../../utils/base";

function DeadlineApprovalModal(props) {
  const {
    title,
    loader,
    handleConfirmation,
    rejectConfirmation,
    isOpen,
    errorMsg,
    complaintData,
    deadline,
    setdeadline,
    feedback,
    setfeedback,
    reason,
    setreason,
    stars,
    setstars,
    imageTest,
    setimageTest,
    loader2,
    OTP,
    setOTP,
  } = props;
  const { TextArea } = Input;

  useEffect(() => {
    setdeadline(complaintData?.data?.extendedDeadline);
  }, [complaintData]);

  return (
    <Modal open={isOpen}>
      <Paper
        style={{
          position: "absolute",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
          padding: 20,
          outline: 0,
          width: 450,
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
        }}
      >
        <Typography
          style={{
            color: "#6c757d",
            fontSize: 19,
            textAlign: "center",
            fontWeight: "bold",
            fontFamily: "Montserrat, sans-serif",
            marginBottom: 7,
            textTransform: "uppercase",
          }}
        >
          {title}
        </Typography>
        <Grid
          container
          item
          xs={12}
          className="justify-content-center mt-4 mb-3"
        >
          {complaintData?.data?.requestType === "deadline extension" &&
            complaintData?.status === "approved" && (
              <input
                style={{
                  borderRadius: 10,
                  padding: 10,
                  border: "1px solid #416082",
                  marginBottom: 8,
                  outline: 0,
                }}
                onChange={(event) => setdeadline(event.target.value)}
                placeholder="deadline"
                type="number"
                value={deadline}
                maxLength={150}
              />
            )}

          {complaintData?.data?.requestType === "close complaint" &&
            complaintData?.status === "approved" && (
              <>
                <Typography
                  style={{
                    color: "#283f59",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 16,
                    marginBottom: 6,
                    marginTop: 16,
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  Enter 6 digit OTP
                </Typography>
                <Grid
                  container
                  style={{ justifyContent: "center", marginTop: 16 }}
                >
                  <OtpInput
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                  />
                </Grid>{" "}
                <Grid
                  container
                  style={{ justifyContent: "center", marginTop: "8px" }}
                >
                  <Grid
                    container
                    style={{
                      padding: 6,
                      borderRadius: 6,
                      backgroundColor: "#4b8edb",
                      color: "#fff",
                      textAlign: "center",
                      border: "2px solid #283f59",
                      width: 300,
                      marginBottom: 10,
                    }}
                  >
                    <Grid item xs={2}>
                      <img
                        class="round"
                        src={
                          complaintData?.data?.raisedBy?.image
                            ? `${BASE_URL}/user/${complaintData?.data?.raisedBy?.image}`
                            : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                        }
                        alt="user"
                        height="50px"
                        width="50px"
                        style={{ borderRadius: 50 }}
                      />
                    </Grid>

                    <Grid item xs={10}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          textDecoration: "underline",
                        }}
                      >
                        {complaintData?.data?.raisedBy?.name}
                      </Typography>
                      <Typography style={{ fontSize: 13 }}>
                        {complaintData?.data?.raisedBy?.email}
                      </Typography>
                      <Typography style={{ fontSize: 13 }}>
                        {complaintData?.data?.raisedBy?.phone}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <TextArea
                  placeholder="Feedback here"
                  className="custom"
                  style={{
                    height: 100,
                    borderRadius: 10,
                  }}
                  onChange={(event) => setfeedback(event.target.value)}
                  value={feedback}
                />
                <Grid container style={{ justifyContent: "center" }}>
                  <span style={{ textAlign: "center" }}>
                    <Rating
                      max={4}
                      name="simple-controlled"
                      value={stars}
                      style={{ marginTop: 10, fontSize: 26 }}
                      onChange={(event, newValue) => {
                        setstars(newValue);
                      }}
                    />
                  </span>
                </Grid>
                <Grid
                  container
                  xs={12}
                  style={{ justifyContent: "center", marginTop: 12 }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      fontFamily: "Montserrat, sans-serif",
                      fontSize: 12,
                      fontWeight: "bold",
                      marginTop: 12,
                    }}
                  >
                    Add Image&nbsp;
                    <span style={{ color: "#000", fontSize: "11px" }}>
                      (Max Size 1MB/IMAGE)
                    </span>
                  </p>
                  <Grid
                    container
                    style={{ justifyContent: "center", marginTop: 20 }}
                  >
                    <input
                      type="file"
                      onChange={(event) => {
                        setimageTest(event);
                      }}
                      style={{
                        width: "100%",
                        outline: 0,
                        backgroundColor: "#4b8edb",
                        marginBottom: 0,
                      }}
                      accept="image/png,image/jpeg"
                    ></input>
                  </Grid>
                </Grid>
              </>
            )}
          {complaintData?.status === "rejected" && (
            <TextArea
              placeholder="Reason here"
              className="custom"
              style={{
                height: 100,
                borderRadius: 10,
              }}
              onChange={(event) => setreason(event.target.value)}
              value={reason}
              maxLength={1000}
            />
          )}
        </Grid>
        {loader && (
          <Typography
            style={{
              color: "#6c757d",
              fontSize: 14,
              textAlign: "center",
              fontFamily: "Montserrat, sans-serif",
              marginTop: 5,
            }}
          >
            Please wait ... &nbsp;
            <LocalDiningOutlined style={{ fontSize: 27, color: "#2A265F" }} />
          </Typography>
        )}
        {errorMsg && (
          <Typography
            style={{
              marginTop: 8,
              color: "red",
              textAlign: "center",
              fontSize: "13px",
              fontWeight: "bold",
            }}
          >
            <ErrorOutline style={{ marginRight: 3 }} />
            {errorMsg}
          </Typography>
        )}
        <Grid container style={{ justifyContent: "space-evenly" }}>
          <Button
            disabled={loader}
            onClick={() => {
              if (!loader) {
                rejectConfirmation();
              }
            }}
            style={{
              backgroundColor: "#C60C30",
              color: "#fff",
              marginTop: "12px",
              padding: 8,
              width: 180,
            }}
          >
            CANCEL
          </Button>
          <Button
            onClick={() => {
              handleConfirmation();
            }}
            style={{
              backgroundColor: `${
                (complaintData?.status === "rejected" && !reason) || loader2
                  ? "#696969"
                  : "#1e2082"
              }`,
              color: "#fff",
              marginTop: "12px",
              padding: 8,
              width: 180,
            }}
            disabled={
              (complaintData?.status === "rejected" && !reason) || loader2
            }
          >
            CONFIRM
          </Button>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default DeadlineApprovalModal;
