import {
  Badge,
  Card,
  CardMedia,
  Checkbox,
  Divider,
  Grid,
  MenuItem,
  Radio,
  Select,
  Typography,
} from "@material-ui/core";
import { AddCircle, Cancel, ErrorOutline } from "@material-ui/icons";
import { PersonSearch } from "@mui/icons-material";
import React from "react";
import SearchAddress from "../../../GlobalComponents/SearchAddress/SearchAddress";
import { BASE_URL } from "../../../utils/base";
import { LoadingOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import ChooseFlatDropDown from "../../../GlobalComponents/DropDowns/ChooseFlatDropDown";
import ChooseCategoryDropDown from "../../../GlobalComponents/DropDowns/ChooseCategoryDropDown";
import ChooseSubCategoryDropDown from "../../../GlobalComponents/DropDowns/ChooseSubCategoryDropDown";
import ChooseFloorDropDown from "../../../GlobalComponents/DropDowns/ChooseFloorDropDown";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  handleGeneratePreSignedURLAWSS3,
  handleUploadImgFile,
  handleUploadInLocal,
} from "../../../utils/awsUpload";
import ChooseAreaDropDown from "../../../GlobalComponents/DropDowns/ChooseAreaDropDown";
import ChooseSubAreaDropDown from "../../../GlobalComponents/DropDowns/ChooseSubAreaDropDown";
import {
  handleFlatName,
  handleFloorName,
  handlePropertyName,
} from "../../../contextAPI/NamingConvention";
import ChooseSubArea2DropDown from "../../../GlobalComponents/DropDowns/ChooseSubArea2DropDown";

const AddSingleComplaint = (props) => {
  const {
    loader,
    complaintType,
    setcomplaintType,
    setComplaintAddress,
    setcomplainerId,
    setbehalfComplaint,
    behalfComplaint,
    setproperty,
    setfloor,
    setflat,
    user,
    seterrorMsg2,
    handleFindUser,
    checkValidation,
    handleAddMoreComplaint,
    setcomplainTitle,
    complainerId,
    searchedComplainer,
    errorMsg2,
    property,
    floor,
    flat,
    TextArea,
    setcomplainDesc,
    errorMsg,
    searchKey,
    setsearchKey,
    setCategory,
    category,
    seterrorMsg,
    handleAddComplaint,
    subCategory,
    setSubCategory,
    setaddMoreComplaint,
    complainTitle,
    complainDesc,
    setsearchedComplainer,
    findUserloader,
    complaintPriority,
    setcomplaintPriority,
    image1,
    setimage1,
    image2,
    setimage2,
    isPlant,
    selectedArea,
    setselectedArea,
    selectedSubArea,
    setselectedSubArea,
    selectedSubArea2,
    setselectedSubArea2,
    setpropertySearch,
    propertySearch,
    setAreaDetails,
    areaDetails,
    isPunePlant,
  } = props;

  return (
    <>
      <Grid container style={{ marginTop: 10 }}>
        <Grid item xs={3}>
          <Typography
            style={{
              color: "#979797",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "18px",
              marginTop: 8,
              marginLeft: 5,
              marginBottom: 4,
            }}
          >
            Title* :
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <input
            style={{
              borderRadius: 16,
              padding: 8,
              width: "98%",
              border: "1px solid #E1E1E1",
              marginBottom: 8,
              outline: 0,
            }}
            type="text"
            placeholder="Enter Complaint Title"
            onChange={(event) => setcomplainTitle(event.target.value)}
            maxLength={150}
            value={complainTitle}
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: 4 }}>
        <Grid
          container
          item
          xs={12}
          style={{ justifyContent: "flex-start", marginTop: 8 }}
        >
          <Typography
            style={{
              color: "#979797",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "18px",
              marginRight: 15,
              marginLeft: 5,
              marginTop: 9,
            }}
          >
            Complaint Type* :
          </Typography>
          <Select
            value={complaintType}
            onChange={(event) => setcomplaintType(event.target.value)}
            style={{
              display: "block",
              // color: "#283f59",
              fontFamily: "Poppins",
              backgroundColor: "#fff",
              borderRadius: 16,
              width: "60%",
              padding: 5,
              paddingLeft: 10,
              height: 46,
              outline: 0,
              // border: "1px solid #E1E1E1",
            }}
          >
            <MenuItem selected value="personal">
              Personal
            </MenuItem>
            <MenuItem selected value="commonArea">
              Common Area
            </MenuItem>
          </Select>
        </Grid>
      </Grid>
      {complaintType === "commonArea" && (
        <Grid container style={{ marginTop: 10 }}>
          <Grid item xs={12}>
            <Typography
              style={{
                color: "#979797",
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "18px",
                marginTop: 5,
                marginLeft: 5,
                marginBottom: 4,
              }}
            >
              Complaint Address :
            </Typography>
            <input
              style={{
                borderRadius: 16,
                padding: 10,
                width: "98%",
                border: "1px solid #E1E1E1",
                marginBottom: 8,
                outline: 0,
              }}
              type="text"
              placeholder="Enter Complaint Adress"
              onChange={(event) => setComplaintAddress(event.target.value)}
            />
          </Grid>
        </Grid>
      )}
      <Grid container style={{ marginBottom: 6, marginTop: 6 }}>
        <Typography
          style={{
            color: "#979797",
            fontFamily: "Poppins",
            fontWeight: "400",
            fontSize: "18px",
            marginTop: 9,
            marginRight: 15,
            marginLeft: 4,
          }}
        >
          Complaint By* :&nbsp;&nbsp;&nbsp;
        </Typography>
        <Checkbox
          value={behalfComplaint}
          color="primary"
          size="small"
          checked={!behalfComplaint}
          onClick={() => {
            setcomplainerId(user._id);
            setproperty([]);
            setsearchedComplainer([]);
            setbehalfComplaint(false);
          }}
          style={{ color: "#283f59", marginTop: 1 }}
        />
        <Typography
          style={{
            color: "#000000",
            fontFamily: "Poppins",
            fontWeight: "400",
            fontSize: "16px",
            marginTop: 8,
          }}
        >
          Self Complaint
        </Typography>
        <Checkbox
          value={behalfComplaint}
          color="primary"
          size="small"
          checked={behalfComplaint}
          onClick={() => {
            setcomplainerId("");
            setbehalfComplaint(true);
          }}
          style={{ color: "#283f59", marginTop: 1, marginLeft: 20 }}
        />
        <Typography
          style={{
            color: "#000000",
            fontFamily: "Poppins",
            fontWeight: "400",
            fontSize: "16px",
            marginTop: 8,
          }}
        >
          Behalf Complaint
        </Typography>
      </Grid>

      {behalfComplaint && (
        <Grid container style={{ marginBottom: 6 }}>
          <Grid container item xs={6}>
            <Typography
              style={{
                color: "#979797",
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "18px",
                marginTop: 4,
                marginLeft: 5,
              }}
            >
              Complainer's Id
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid container>
        {behalfComplaint && (
          <Grid container item xs={5}>
            <input
              style={{
                borderRadius: 10,
                padding: 10,
                width: "98%",
                border: "1px solid #4b8edb",
                marginBottom: 8,
                outline: 0,
                height: 50,
              }}
              type="text"
              placeholder="Ex: MANQ8leZJfIh9vG"
              onChange={(event) => {
                setproperty([]);
                setfloor([]);
                setflat([]);
                setcomplainerId(event.target.value);
              }}
              value={complainerId}
              disabled={!behalfComplaint}
            />
          </Grid>
        )}
        {behalfComplaint && (
          <PersonSearch
            title="Seach User"
            style={{
              color: "#4b8edb",
              fontSize: 34,
              marginTop: 5,
              cursor: "pointer",
              backgroundColor: "#fff",
              padding: 6,
              borderRadius: 50,
              border: "1px solid #4b8edb",
              marginRight: 10,
            }}
            onClick={() => {
              if (complainerId === "") {
                seterrorMsg2("Please enter a complainerId first");
              } else {
                handleFindUser();
              }
            }}
          />
        )}
        {behalfComplaint && searchedComplainer?._id && (
          <Grid container item xs={6}>
            <Card
              style={{
                backgroundImage:
                  "radial-gradient(circle at 48.7% 44.3%, rgb(30, 144, 231) 0%, rgb(56, 113, 209) 22.9%, rgb(38, 76, 140) 76.7%, rgb(31, 63, 116) 100.2%)",
                borderRadius: "7px",
                display: "flex",
                overflow: "hidden",
                width: "50vw",
                boxShadow:
                  "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
              }}
            >
              <div style={{ width: "300px", padding: 10 }}>
                <Grid container style={{ justifyContent: "center" }}>
                  <img
                    class="round"
                    src={
                      searchedComplainer?.image
                        ? `${BASE_URL}/user/${searchedComplainer.image}`
                        : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                    }
                    alt="user"
                    height="35px"
                    width="35px"
                    style={{ borderRadius: 50, marginTop: 2 }}
                  />
                </Grid>
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "Montserrat, sans-serif",
                    color: "#ffffff",
                    fontSize: 12,
                    fontWeight: "bold",
                  }}
                >
                  {searchedComplainer?.role}
                </Typography>
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "Montserrat, sans-serif",
                    color: "#ffffff",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  {searchedComplainer?.name} &nbsp;
                </Typography>
                <Typography
                  style={{
                    fontSize: 13,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#fff",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Phone : &nbsp;
                  <span style={{ fontWeight: "500" }}>
                    {searchedComplainer?.phone}
                  </span>
                </Typography>
                <Divider
                  style={{
                    marginTop: 5,
                    marginBottom: 5,
                    backgroundColor: "#000",
                  }}
                />
                <Typography
                  style={{
                    fontSize: 13,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#fff",
                    textAlign: "start",
                    fontWeight: "bold",
                  }}
                >
                  {handlePropertyName()} : &nbsp;
                  <span style={{ fontWeight: "500" }}>
                    {property?.name}({property?.type})
                  </span>
                </Typography>
                <Typography
                  style={{
                    fontSize: 13,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#fff",
                    textAlign: "start",
                    fontWeight: "bold",
                  }}
                >
                  {handleFloorName()} : &nbsp;
                  <span style={{ fontWeight: "500" }}>{floor?.name}</span>
                </Typography>
                <Typography
                  style={{
                    fontSize: 13,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#fff",
                    textAlign: "start",
                    fontWeight: "bold",
                  }}
                >
                  {handleFlatName()} : &nbsp;
                  <span style={{ fontWeight: "500" }}>{flat?.name}</span>
                </Typography>
              </div>
            </Card>
          </Grid>
        )}
        {errorMsg2 && (
          <Typography style={{ marginTop: 7, color: "red" }}>
            {errorMsg2}
          </Typography>
        )}
        {findUserloader && (
          <Typography style={{ marginTop: 7, color: "#283f59" }}>
            Searching User...
          </Typography>
        )}
      </Grid>

      {!behalfComplaint ? (
        <>
          <Typography
            style={{
              color: "#979797",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "18px",
              marginRight: 15,
              marginLeft: 5,
              marginTop: 6,
            }}
          >
            {complaintType === "commonArea"
              ? "Nearby Address* :"
              : "Complaint Address* :"}
          </Typography>
          <Grid container style={{ marginTop: 12, marginLeft: 5 }}>
            <Grid container item xs={5}>
              <SearchAddress
                searchKey={propertySearch}
                setsearchKey={setpropertySearch}
                setPropertyId={setproperty}
                propertyId={property}
                isMultiple={false}
              />
            </Grid>
            {complaintType !== "commonArea" && (
              <>
                <Grid container item xs={4}>
                  <ChooseFloorDropDown
                    property={property}
                    floor={floor}
                    setfloor={setfloor}
                  />
                </Grid>
                <Grid container item xs={3}>
                  <ChooseFlatDropDown
                    floor={floor}
                    setflat={setflat}
                    flat={flat}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </>
      ) : (
        <></>
      )}

      <Grid container style={{ marginBottom: 6, marginTop: 6 }}>
        <Grid container item xs={6}>
          <Typography
            style={{
              color: "#979797",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "18px",
              marginTop: 4,
              marginLeft: 5,
            }}
          >
            Choose Category*
          </Typography>
        </Grid>
        <Grid container item xs={6}>
          <Typography
            style={{
              color: "#979797",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "18px",
              marginTop: 4,
              marginLeft: 5,
            }}
          >
            Choose Sub Category*
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: 4 }}>
        <Grid container item xs={6} style={{ justifyContent: "center" }}>
          <ChooseCategoryDropDown
            setCategory={setCategory}
            category={category}
            isMultiple={false}
            searchKey={searchKey}
            setsearchKey={setsearchKey}
          />
        </Grid>
        <Grid container item xs={6} style={{ justifyContent: "center" }}>
          <ChooseSubCategoryDropDown
            setSubCategory={setSubCategory}
            subCategory={subCategory}
            isMultiple={false}
            category={category}
            searchKey={searchKey}
            setsearchKey={setsearchKey}
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: 6, marginTop: 6 }}>
        {(isPunePlant ? isPunePlant && flat?.name === "Toilet" : isPlant) && (
          <>
            <Grid container item xs={isPunePlant ? 4 : 6}>
              <Typography
                style={{
                  color: "#979797",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "18px",
                  marginTop: 4,
                  marginLeft: 5,
                }}
              >
                Choose Area *
              </Typography>
            </Grid>
            <Grid container item xs={isPunePlant ? 4 : 6}>
              <Typography
                style={{
                  color: "#979797",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "18px",
                  marginTop: 4,
                  marginLeft: 5,
                }}
              >
                Choose Location *
              </Typography>
            </Grid>
          </>
        )}
        {isPunePlant && flat?.name === "Toilet" && (
          <Grid container item xs={4}>
            <Typography
              style={{
                color: "#979797",
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "14px",
                marginTop: 4,
                marginLeft: 5,
              }}
            >
              Choose Detail Address *
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container style={{ marginBottom: "8px" }}>
        {(isPunePlant ? isPunePlant && flat?.name === "Toilet" : isPlant) && (
          <>
            <Grid
              container
              item
              xs={isPunePlant ? 4 : 6}
              style={{ justifyContent: "center" }}
            >
              <ChooseAreaDropDown
                selectedArea={selectedArea}
                setselectedArea={setselectedArea}
                property={property}
                isPunePlant={isPunePlant}
                flat={flat}
              />
            </Grid>
            <Grid
              container
              item
              xs={isPunePlant ? 4 : 6}
              style={{ justifyContent: "center" }}
            >
              <ChooseSubAreaDropDown
                selectedSubArea={selectedSubArea}
                setselectedSubArea={setselectedSubArea}
                selectedArea={selectedArea}
                floor={floor}
                isPunePlant={isPunePlant}
                flat={flat}
              />
            </Grid>
          </>
        )}
        {isPunePlant && flat?.name === "Toilet" && (
          <Grid
            container
            item
            xs={isPunePlant ? 4 : 6}
            style={{ justifyContent: "center" }}
          >
            <ChooseSubArea2DropDown
              selectedSubArea2={selectedSubArea2}
              setselectedSubArea2={setselectedSubArea2}
              selectedSubArea={selectedSubArea}
            />
          </Grid>
        )}
      </Grid>

      {isPlant && selectedArea?._id === "66d20f3028d12ce3073c75ac" && (
        <Grid container>
          <Typography
            style={{
              color: "#979797",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "18px",
              marginTop: 5,
              marginLeft: 5,
              marginBottom: 4,
            }}
          >
            Other Details
          </Typography>
          <input
            style={{
              padding: 8,
              width: "98%",
              borderRadius: 8,
              border: "1px solid #E1E1E1",
              marginBottom: 8,
              outline: 0,
            }}
            type="text"
            placeholder="Please specify the area and other necessary details"
            onChange={(event) => setAreaDetails(event.target.value)}
            maxLength={150}
            value={areaDetails}
          />
        </Grid>
      )}

      <Grid container>
        <Grid item xs={12}>
          <Typography
            style={{
              color: "#979797",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "18px",
              marginTop: 5,
              marginLeft: 5,
              marginBottom: 4,
            }}
          >
            Complaint Description*
          </Typography>
          <TextArea
            style={{
              height: 100,
              borderRadius: 16,
              border: "1px solid #E1E1E1",
              marginBottom: "5px",
            }}
            value={complainDesc}
            onChange={(event) => setcomplainDesc(event.target.value)}
            placeholder={
              category?._id === "66d2128b28d12ce3073c80b9" &&
              selectedArea?._id !== "66d20f3028d12ce3073c75ac"
                ? "Enter phone number thats not working and other necessary details"
                : selectedArea?._id === "66d20f3028d12ce3073c75ac" &&
                  category?._id !== "66d2128b28d12ce3073c80b9"
                ? "Please specify the area and other necessary details"
                : category?._id === "66d2128b28d12ce3073c80b9" &&
                  selectedArea?._id === "66d20f3028d12ce3073c75ac"
                ? "Enter the phone number that’s not working. Please specify the area and any other necessary details."
                : "Enter Description Here.."
            }
            maxLength={250}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Typography
            style={{
              color: "#979797",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "18px",
              marginTop: 5,
              marginLeft: 5,
              marginBottom: 4,
            }}
          >
            Complaint Priority*
          </Typography>
          <Grid container style={{ justifyContent: "center", marginTop: 10 }}>
            <Grid style={{ display: "flex" }}>
              <Radio
                color="primary"
                checked={complaintPriority === "high"}
                onClick={() => {
                  setcomplaintPriority("high");
                }}
              />
              <Typography
                style={{
                  color: "#000000",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "18px",
                  marginTop: "5px",
                }}
              >
                High
              </Typography>
            </Grid>
            <Grid style={{ marginLeft: 12, display: "flex" }}>
              <Radio
                color="primary"
                checked={complaintPriority === "low"}
                onClick={() => {
                  setcomplaintPriority("low");
                }}
              />
              <Typography
                style={{
                  color: "#000000",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "18px",
                  marginTop: "5px",
                }}
              >
                Low
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            style={{
              color: "#283f59",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "600",
              marginTop: 5,
              marginLeft: 5,
              marginBottom: 4,
            }}
          >
            Complaint Image
          </Typography>
          <input
              type="file"
              onChange={(event) => {
                setimage1(event);
              }}
              style={{
                width:"100%",
                backgroundColor: "#4b8edb",
                marginBottom: 0,
              }}
              accept="image/png,image/jpeg"
            ></input>
        </Grid>
      </Grid>

      {errorMsg && (
        <Typography
          style={{
            marginTop: 10,
            color: "red",
            textAlign: "center",
            fontSize: "13px",
            fontWeight: "bold",
          }}
        >
          <ErrorOutline style={{ marginRight: 3 }} />
          {errorMsg}
        </Typography>
      )}
      <Grid container style={{ justifyContent: "center", marginTop: 40 }}>
        <div
          class="card"
          style={{
            backgroundColor: "#3862DD",
            color: "#ffffff",
            cursor: "pointer",
            borderRadius: 10,
          }}
          onClick={() => {
            if (!loader) {
              handleAddComplaint();
            } else {
              //Nothing happened
            }
          }}
          disabled={loader}
        >
          <div class="card-body ">
            <p
              class="card-text"
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: "Poppins",
                textAlign: "center",
              }}
            >
              <>
                {loader ? (
                  <LoadingOutlined
                    style={{
                      marginRight: 10,
                      fontSize: "15px",
                    }}
                  />
                ) : (
                  <AddCircle
                    style={{
                      marginRight: 10,
                      fontSize: "15px",
                    }}
                  />
                )}

                {!loader ? "Submit Complaint" : `Submiting...`}
              </>
            </p>
          </div>
        </div>
        <div
          class="card"
          style={{
            backgroundColor:
              checkValidation() === false || loader ? "#7D858D" : "#3862DD",
            color: "#ffffff",
            cursor: "pointer",
            marginLeft: "12px",
            borderRadius: 10,
          }}
          onClick={() => {
            if (checkValidation() === false) {
              toast.error("all * fields are required");
            } else {
              seterrorMsg("");
              handleAddMoreComplaint();
              setaddMoreComplaint(true);
            }
          }}
        >
          <div class="card-body ">
            <p
              class="card-text"
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: "Poppins",
                textAlign: "center",
              }}
            >
              <AddCircle
                style={{
                  marginRight: 10,
                  fontSize: "15px",
                }}
              />
              Add Multiple Complaint
            </p>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default AddSingleComplaint;
